@charset 'UTF-8';
@import "_ress.min";
@import "_vars";

/* ------------------------------------------------------------------------
  General
------------------------------------------------------------------------ */
html {
  width: 100%;
  font-size: 62.5%;
}

body {
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W6", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.7;
  color: $color_text;
  @include min-screen($pc_size) {
    min-width: 1100px;
  }
  @include max-screen($sp_size) {
    width: 100%;
    overflow: hidden;
  }

  /* font-weight for Noto Sans
  ------------------------------
  400: regular
  500: medium
  600: semi-bold
  700: bold
  ------------------------------*/
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  vertical-align: top;
  @include max-screen($sp_size) {
    width: 100%;
  }
}

ul,
ol {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
  @include min-screen($pc_size) {
    &:hover {
      opacity: 0.8;
    }
  }

  &.link_underline {
    text-decoration: underline;
  }
}

/* Layout
----------------------------------------- */
@include min-screen($pc_size) {
  .sp {
    display: none !important;
  }
}
@include max-screen($sp_size) {
  .pc {
    display: none !important;
  }
}

.inner {
  @include min-screen($pc_size) {
    @include contents_width_pc;  
  }
  @include max-screen($sp_size) {
    @include contents_width_sp; 
  }
}

.flex {
  @include flex;
}

.inline-flex {
  @include inline-flex;
}

/* Button
----------------------------------------- */
.btn {

}

/* Text
----------------------------------------- */
.marker {
  // background: linear-gradient(transparent 60%, #EFC000 60%);
  background-color: #EFC000;
}

/* Animate
----------------------------------------- */
.ani {
  transition-duration: 1s;
  transition-delay: 0.4s;

  // Fade In
  &.ani_fadeIn {
    opacity: 0;
    &.animated {
      opacity: 1;
    }
  }

  // Fade In Bottom
  &.ani_fadeInBottom {
    opacity: 0;
    transform: translateY(20px);
    &.animated {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

// Shake
.shake_box {
  display: block;
  &.shaking {
    pointer-events: none;

    figure {
      animation: shake 1s ease-in-out;
    }
  }
}

@keyframes shake {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(-8deg);
  }
  70% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-3deg);
  }
  90% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0);
  }
}


/* ------------------------------------------------------------------------
  Main
------------------------------------------------------------------------ */

// Opening Animation
body {
  height: 100vh;
  background-color: #000;
  overflow: hidden;

  .oa {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
    background-color: inherit;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  // .wrapper {
  //   opacity: 0;
  //   transition: opacity 2s;
  // }

  &.loaded {
    height: auto;
    background-color: transparent;
    overflow: auto;
    
    // .oa {
    //   display: none;
    // }

    // .wrapper {
    //   opacity: 1;
    // }
  }
}

main {
  section {
    text-align: center;

    h2 {
      margin-bottom: 30px;
    }

    .inner > p {
      text-align: left;
    }

    &.sec_mv {
      height: 757px;
      padding: 150px 0 0;
      overflow: hidden;
      background-image: url(../img/mv_bg.svg);
      background-repeat: repeat-x;
      background-position: center;
      @include max-screen($sp_size) {
        height: auto;
        padding: 0;
        background-image: url(../img/mv_bg_sp.svg);
        background-position: center bottom;
        background-size: cover;
      }

      .logo {
        padding: 25px 20px;
        background-color: #fff;
        border: 2px solid $color_text;
        border-radius: 0 0 10px 10px;
        border-top: none;
        text-align: center;
        position: absolute;
        top: 0;
        left: 25px;
        @include max-screen($sp_size) {
          // @include flex;
          // align-items: center;
          // justify-content: center;
          // width: 90vw;
          // padding: 20px 0;
          // left: 5vw;

          @include flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 40px;
          padding: 9px 5vw;
          border: none;
          border-radius: 0;
          position: static;
        }

        p {
          margin-bottom: 7px;
          font-size: 1.0rem;
          font-weight: bold;
          @include max-screen($sp_size) {
            margin-bottom: 0;
          }
        }

        img {
          @include max-screen($sp_size) {
            width: 85px;
          }
        }
      }

      h1 {
        margin-bottom: 65px;
        position: relative;
        z-index: 1;
        @include max-screen($sp_size) {
          margin-bottom: 35px;
        }
      }

      .mv_box {
        position: relative;

        img {
          position: relative;
        }

        &:before {
          content: "";
          width: 678px;
          height: 678px;
          background-image: url(../img/mv_halo.png);
          background-repeat: no-repeat;
          position: absolute;
          top: -200px;
          left: calc(50% - (678px / 2));
          animation: halo 60s linear infinite;
          @include max-screen($sp_size) {
            width: 120vw;
            height: 120vw;
            background-size: contain;
            top: -30vw;
            left: calc(50% - 60vw);
          }
        }
      }

      @keyframes halo {
        0% {
          transform: rotate(0) scale(1, 1);
        }
        0.5% {
          transform: rotate(2deg) scale(1.1, 1.1);
        }
        1% {
          transform: rotate(3deg) scale(1, 1);
        }
        50% {
          transform: rotate(180deg) scale(1, 1);
        }
        100% {
          transform: rotate(360deg) scale(1, 1);
        }
      }
    }

    &.sec_about {
      padding: 80px 0 100px;
      background-image: url(../img/about_bg.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: relative;
      @include max-screen($sp_size) {
        padding: 50px 0 0;
      }

      &:after {
        content: "";
        display: block;
        width: 852px;
        height: 203px;
        margin: -30px auto 0;
        background-image: url(../img/about_landscape.png);
        background-repeat: no-repeat;
        background-position: center;
        @include max-screen($sp_size) {
          content: none;
          width: 100%;
          height: 22vw;
          margin: -10px auto 0;
          background-size: contain;
        }
      }

      .inner > p {
        width: 830px;
        margin: 0 auto 30px;
        font-size: 1.7rem;
        font-weight: 500;
        text-align: center;
        @include max-screen($sp_size) {
          width: auto;
          font-size: 1.6rem;
        }
      }

      .about_diamond {
        width: 900px;
        margin: 40px auto 0;
        font-size: 0;
        @include max-screen($sp_size) {
          width: auto;
          padding-top: 40px;
          position: relative;
        }

        img {
          margin: 0 5px;
          font-size: 1.6rem;
          &.about_diamond_hands {
            margin: 0 20px;
            @include max-screen($sp_size) {
              width: 136px;
            }
          }
          &:not(.about_diamond_hands) {
            position: relative;
            @include max-screen($sp_size) {
              width: 60px;
              position: absolute;
            }
          }
          &.about_diamond_pandora {
            top: 20px;
            animation: diamond_pandora 1.5s ease-in-out infinite alternate;
            @include max-screen($sp_size) {
              top: 0;
              left: 0;
            }
          }
          &.about_diamond_person {
            top: 60px;
            animation: diamond_person 1.5s ease-in-out infinite alternate;
            @include max-screen($sp_size) {
              top: 20px;
              left: 60px;
            }
          }
          &.about_diamond_potential {
            top: 20px;
            animation: diamond_potential 1.5s ease-in-out infinite alternate;
            @include max-screen($sp_size) {
              top: 60px;
              left: 10px;
            }
          }
          &.about_diamond_chance {
            top: 20px;
            animation: diamond_chance 1.5s ease-in-out infinite alternate;
            @include max-screen($sp_size) {
              top: 60px;
              right: 10px;
            }
          }
          &.about_diamond_change {
            top: 60px;
            animation: diamond_change 1.5s ease-in-out infinite alternate;
            @include max-screen($sp_size) {
              top: 20px;
              right: 60px;
            }
          }
          &.about_diamond_channel {
            top: 20px;
            animation: diamond_channel 1.5s ease-in-out infinite alternate;
            @include max-screen($sp_size) {
              top: 0;
              right: 0;
            }
          }
        }

        &:after {
          @include max-screen($sp_size) {
            content: "";
            display: block;
            width: 100%;
            height: 22vw;
            margin: -10px auto 0;
            background-image: url(../img/about_landscape.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }
        }
      }

      @keyframes diamond_pandora {
        0% {
          transform: translateY(0);
        }
        20% {
          transform: translateY(0);
        }
        80% {
          transform: translateY(-5px);
        }
        100% {
          transform: translateY(-5px);
        }
      }
      @keyframes diamond_person {
        0% {
          transform: translateY(0);
        }
        20% {
          transform: translateY(0);
        }
        80% {
          transform: translateY(5px);
        }
        100% {
          transform: translateY(5px);
        }
      }
      @keyframes diamond_potential {
        0% {
          transform: translateY(0);
        }
        20% {
          transform: translateY(0);
        }
        80% {
          transform: translateY(-5px);
        }
        100% {
          transform: translateY(-5px);
        }
      }
      @keyframes diamond_chance {
        0% {
          transform: translateY(0);
        }
        20% {
          transform: translateY(0);
        }
        80% {
          transform: translateY(-5px);
        }
        100% {
          transform: translateY(-5px);
        }
      }
      @keyframes diamond_change {
        0% {
          transform: translateY(0);
        }
        20% {
          transform: translateY(0);
        }
        80% {
          transform: translateY(5px);
        }
        100% {
          transform: translateY(5px);
        }
      }
      @keyframes diamond_channel {
        0% {
          transform: translateY(0);
        }
        20% {
          transform: translateY(0);
        }
        80% {
          transform: translateY(-5px);
        }
        100% {
          transform: translateY(-5px);
        }
      }

      .about_pandra {
        @include flex;
        align-items: center;
        justify-content: center;
        padding: 50px 90px;
        background-image: url(../img/about_pandora_bg.png);
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        bottom: -250px;
        left: calc(50% - 550px);
        z-index: 10;
        @include max-screen($sp_size) {
          display: block;
          width: 100%;
          padding: 40px 4vw;
          background-image: url(../img/about_pandora_bg_sp.png);
          background-repeat: repeat-y;
          background-position: center top;
          background-size: 100%;
          border-top: 2px solid $color_text;
          border-bottom: 2px solid $color_text;
          position: relative;
          bottom: 1vw;
          left: 0;
        }

        &:after {
          content: "";
          display: block;
          width: 285px;
          height: 260px;
          background-image: url(../img/about_pandora_box.svg);
          background-repeat: no-repeat;
          background-position: center;
          @include max-screen($sp_size) {
            // width: 200px;
            // height: 180px;
            width: 54vw;
            height: 48vw;
            margin: auto;
            background-size: contain;
          }
        }

        dl {
          padding: 0 65px 0 0;
          @include max-screen($sp_size) {
            padding: 0 0 20px;
          }
          dt {
            font-size: 3.4rem;
            font-weight: bold;
            @include max-screen($sp_size) {
              font-size: 2.6rem;
            }
          }
          dd {
            width: 560px;
            margin-top: 20px;
            font-weight: 500;
            @include max-screen($sp_size) {
              width: auto;
              margin-top: 10px;
              font-size: 1.5rem;
            }
          }
        }
      }
    }

    &.sec_service {
      padding: 300px 0 90px;
      background-image: url(../img/service_bg.png);
      background-repeat: no-repeat;
      background-position: center top;
      background-size: cover;
      @include max-screen($sp_size) {
        margin-top: -65vw;
        padding: 80vw 0 80px;
        position: relative;
      }

      .inner > p {
        width: 480px;
        margin: 0 auto 30px;
        font-weight: 500;
        @include max-screen($sp_size) {
          width: auto;
          font-size: 1.5rem;
        }
      }

      .service_link {
        > p {
          display: inline-block;
          margin-bottom: 10px;
          font-size: 1.9rem;
          font-weight: bold;
          @include max-screen($sp_size) {
            margin-bottom: 50px;
            font-size: 1.7rem;
          }

          &:after {
            content: "";
            display: block;
            width: 310px;
            height: 5px;
            margin-top: 5px;
            background-image: url(../img/service_underline.svg);
            background-repeat: no-repeat;
            background-position: center;
            @include max-screen($sp_size) {
              width: 98%;
            }
          }
        }

        .service_box {
          @include flex;
          align-items: flex-end;
          justify-content: center;

          li {
            margin: 0 10px;
            position: relative;
            @include max-screen($sp_size) {
              margin: 20px auto;
            }

            a {
              display: block;

              > div {
                margin: auto;
              }
            }

            .service_box_name {
              @include flex;
              align-items: center;
              justify-content: center;
              width: 310px;
              height: 80px;
              margin-top: 10px;
              background-repeat: no-repeat;
              background-position: center top 2px;
              font-size: 2.0rem;
              font-weight: bold;
              line-height: 1.25;
              @include max-screen($sp_size) {
                width: 300px;
                height: 78px;
                margin-top: 5px;
                background-position: center top 1px;
                background-size: contain;
              }
              &.service_box_name01 {
                background-image: url(../img/service_box_name01.svg);
              }
              &.service_box_name02 {
                background-image: url(../img/service_box_name02.svg);
              }
              &.service_box_name03 {
                background-image: url(../img/service_box_name03.svg);
              }
            }

            &.comingsoon {
              a {
                pointer-events: none;
              }

              figure {
                img {
                  @include max-screen($sp_size) {
                    width: auto;
                  }
                }

                &:after {
                  content: "COMING SOON";
                  width: 100%;
                  color: #fff;
                  font-size: 2.8rem;
                  font-weight: bold;
                  line-height: 1;
                  @include centering_horizontally;
                  top: 105px;
                }
              }
            }
          }
        }
      }
    }

  }//section
}//main


/* ------------------------------------------------------------------------
  Footer
------------------------------------------------------------------------ */
footer {
  padding: 15px 0;
  background-color: $color_bg;
  color: #fff;
  text-align: center;

  a {
    font-size: 1.4rem;
    font-weight: 500;
  }

  small {
    display: block;
    margin-top: 20px;
    font-size: 1.0rem;
    @include max-screen($sp_size) {
      margin-top: 15px;
    }
  }
}