// Colors
$color_text: #3A210B;
$color_bg: #311301;

// Width
@mixin contents_width_pc {
  max-width: 1130px;
  padding: 0 15px;
  margin: auto;
}
// common padding
@mixin contents_width_sp {
  padding-left: 5vw;
  padding-right: 5vw;
}

// Break Point
$sp_size: 767px;
$pc_size: 768px;
@mixin max-screen($size) {
  @media screen and (max-width: $size) {
    @content;
  }
}
@mixin min-screen($size) {
  @media screen and (min-width: $size) {
    @content;
  }
}

@mixin flex {
  display: flex;
  flex-wrap: wrap;
}

@mixin inline-flex {
  display: inline-flex;
  flex-wrap: wrap;
}

// Centering  Horizontally & Vertically
@mixin centering {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
// Centering  Horizontally
@mixin centering_horizontally {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
// Centering  Vertically
@mixin centering_vertically {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}